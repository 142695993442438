<template>
  <section id="modulo-accesso" bind:this={elemento}>
    <div class="modulo">
      <h2>{t.accedi[$l]}</h2>
      <Modulo
          {indirizzo}
          {parametri}
          metodo="POST"
          gestore={gestore_risposta}
          validatore={validatore_modulo}
          bind:in_salvataggio={autenticazione_in_corso}
          bind:errori={errori}>

        {#if errori.generico != null}
          <div class="errore-generico">
            {t.errori[errori.generico][$l]}.
          </div>
        {/if}

        <Inserimento
            tipo="email"
            etichetta={t.email[$l]}
            errore={errori.email != null ? t.validazioni[errori.email][$l] : null}
            segnaposto={t.email_segnaposto[$l]}
            bind:valore={email}/>
        <Inserimento
            tipo="password"
            etichetta={t.password[$l]}
            errore={errori.password != null ? t.validazioni[errori.password][$l] : null}
            segnaposto={t.password_segnaposto[$l]}
            bind:valore={password}/>
        {#if autenticazione_in_corso}
          <Caricatore/>
        {:else}
          <div class="azioni">
            <Bottone tipo="invio" variante="primario grande">
              {t.accedi[$l]}
            </Bottone>
          </div>
          <div class="collegamenti">
            <div class="collegamento">
              {#if !accesso_staff}
                <span>{t.accetta_privacy[$l]}</span>
              {/if}
              <a href="{collegamenti_riserbo[$l]}" target="_blank">
                Privacy Policy
              </a>
            </div>

            {#if !accesso_staff}
              <div class="collegamento">
                <span>{t.hai_dimenticato_la_password[$l]}</span>
                <a href="{'#'}"
                    on:click|preventDefault={vai_a_recupero_password}>
                  {t.recupera[$l]}
                </a>
              </div>

              <div class="collegamento">
                <span>{t.non_sei_registrato[$l]}</span>
                <a href="{'#'}"
                    on:click|preventDefault={vai_a_registrazione}>
                  {t.registrati[$l]}
                </a>
              </div>
            {/if}
          </div>
        {/if}
      </Modulo>
    </div>
  </section>
</template>

<style>
  :global(section#modulo-accesso) {
    width:          38.2%;
    max-width:      450px;
    height:         calc(100vh - var(--top-margin));
    position:       fixed;
    top:            var(--top-margin);
    left:           43%;
    display:        flex;
    align-items:    flex-start;
  }
  :global(section#modulo-accesso div.modulo) {
    width:          100%;
    background:     var(--colore-sfondo);
    padding:        32px;
    border-radius:  5px;
    box-shadow:     0px 10px 20px rgba(0, 0, 0, 0.1);
  }
  :global(section#modulo-accesso div.modulo h2) {
    margin-top: 0;
    color:      var(--colore-titolo);
  }
  :global(section#modulo-accesso div.errore-generico) {
    color:          var(--colore-rosso);
    margin-bottom:  14px;
  }
  :global(section#modulo-accesso div.modulo form) {
    width:          100%;
  }
  :global(section#modulo-accesso div.modulo div.collegamenti) {
    margin-top:     28px;
  }
  :global(section#modulo-accesso div.modulo div.collegamenti div.collegamento) {
    display:          flex;
    align-items:      center;
    justify-content:  flex-start;
    font-size:        12px;
  }
  :global(section#modulo-accesso div.modulo div.collegamenti div.collegamento a) {
    margin:           0 8px;
    line-height:      30px;
    font-size:        12px;
  }
</style>

<script>
  import Modulo from "@/base/componenti/Modulo.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import { utente_corrente } from "@/base/sorgenti/svuby"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { dominio } from "@/portale/sorgenti/dominio"
  import { querystring } from "svelte-spa-router"
  import { onMount } from "svelte"
  import { onDestroy } from "svelte"

  export let accesso_staff = false

  const t = avvia_localizzazione(dizionario_accesso)
  const gestione_errore_precedente = window.onunhandledrejection
  const collegamenti_riserbo = {
    it: `https://www.iubenda.com/privacy-policy/81333460`,
    en: `https://www.iubenda.com/privacy-policy/40732172`,
    de: `https://www.iubenda.com/privacy-policy/74782468`
  }

  let elemento
  let email = null
  let password = null
  let errori = { email: null, password: null, generico: null }
  let autenticazione_in_corso = false
  let nuovo_url = null
  let client_id = null

  $: indirizzo = client_id != null ? retro.estremi.anagrafe.autorizza_sso : retro.estremi.anagrafe.autentica
  $: parametri = { email, password, client_id }

  ////
  // Effettua l'accesso autenticando l'utente.
  function accedi(risposta) {
    if (risposta.contenuto.gettone_sso != null)
      nuovo_url = inserisci_gettone_sso(nuovo_url, risposta.contenuto.gettone_sso)
    else {
      biscottini.deposita("gettone", risposta.contenuto.gettone)
      utente_corrente.cambia_in(risposta.contenuto.utente)

      if (window.dataLayer != null) {
        let dati_login = {
          event: "login",
          ruolo: risposta.contenuto.utente.ruolo,
          tipo: risposta.contenuto.utente.tipo_prima_sessione,
          destinazione: risposta.contenuto.utente.destinazione_prima_sessione
        }

        if (risposta.contenuto.utente.categoria_prima_sessione != null)
          dati_login.categoria = risposta.contenuto.utente.categoria_prima_sessione
        else dati_login.categoria = "Destinazione"

        window.dataLayer.push(dati_login)
      }

      console.log(`Autenticato: ${$utente_corrente.email.colore("azzurro")}`)
    }

    if (nuovo_url != null && nuovo_url.includes("https://portal.hbenchmark.it/resoconti_finanziari/"))
      window.open(nuovo_url)
    if (nuovo_url != null) location.assign(nuovo_url)
    if (nuovo_url == null || nuovo_url.includes(window.location.origin))
      location.reload()
  }

  ////
  // Va alla pagina di registrazione.
  function vai_a_registrazione(evento) {
    navigatore.verso("/registrazione")
  }

  ////
  // Va alla pagina di recupero della password.
  function vai_a_recupero_password(evento) {
    navigatore.verso("/recupero")
  }

  ////
  // Validazione del modulo.
  function validatore_modulo() {
    if (email == null || email == "")
      errori.email = "richiesto"
    if (password == null || password == "")
      errori.password = "richiesto"
  }

  ////
  // Gestisce gli errori di autenticazione.
  function gestisci_errore_autenticazione(messaggio, sorgente, riga, colonna, errore) {
    if (messaggio != null && messaggio.reason != null && messaggio.reason.non_autorizzato) {
      errori.generico = "credenziali_non_valide"
    } else {
      gestione_errore_precedente(...arguments)
    }

    autenticazione_in_corso = false
  }

  ////
  // Gestione della gestione del modulo.
  function gestore_risposta(risposta) {
    if (risposta.riuscita) return accedi(risposta)
    switch (risposta.stato) {
      case 404:
        errori.generico = "credenziali_non_valide"
        break
      default:
        errori.generico = "altro"
    }

    autenticazione_in_corso = false
  }

  ////
  // Inserisce il gettone SSO nell'indirizzo di reindirizzo alla'accesso.
  function inserisci_gettone_sso(url, gettone_sso) {
    if (url == null) return

    let url_base = url.split("?")[0]
    let query = url.split("?")[1]

    parametri = query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params, param) => {
                  let [key, value] = param.split('=');
                  params[decodeURIComponent(key)] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                  return params;
              }, {}
          )
      : {}
    parametri["gettone_sso"] = gettone_sso

    return `${url_base}?${serializza(parametri)}`
  }

  // Gestione errore autenticazione.
  onMount(() => {
    window.onunhandledrejection = gestisci_errore_autenticazione

    let parametri = new URLSearchParams($querystring)

    if ($querystring.includes("redirect_url=")) nuovo_url = parametri.get("redirect_url")
    if ($querystring.includes("client_id=")) client_id = parametri.get("client_id")
  })
  onDestroy(() => window.onunhandledrejection = gestione_errore_precedente)
</script>

<script context="module">
  export const dizionario_accesso = {
    email: {
      it: `Email *`,
      en: `Email *`,
      de: `Email *`
    },
    email_segnaposto: {
      it: `Inserisci la tua email`,
      en: `Write your email`,
      de: `E-Mail Adresse eingeben`
    },
    password: {
      it: `Password *`,
      en: `Password *`,
      de: `Passwort *`
    },
    password_segnaposto: {
      it: `Inserisci la tua password`,
      en: `Write your password`,
      de: `Passwort eingeben`
    },
    accedi: {
      it: `Accedi`,
      en: `Login`,
      de: `Anmeldung`
    },
    recupera: {
      it: `Recupera`,
      en: `Recover`,
      de: `Wiederherstellen`
    },
    registrati: {
      it: `Registrati`,
      en: `Register`,
      de: `Registrieren`
    },
    hai_dimenticato_la_password: {
      it: `Hai dimenticato la password?`,
      en: `Forgot your password?`,
      de: `Passwort vergessen?`
    },
    non_sei_registrato: {
      it: `Non hai un account?`,
      en: `Don't have an account?`,
      de: `Sie haben kein Konto?`
    },
    accetta_privacy: {
      it: `Cliccando su Iscriviti accetti la nostra`,
      en: `By clicking on Subscribe you accept ours`,
      de: `Durch Klicken auf Abonnieren akzeptieren Sie unsere`
    },
    errori: {
      credenziali_non_valide: {
        it: `Credenziali non valide`,
        en: `Invalid credentials`,
        de: `Ungültige Zugangsdaten`
      },
      altro: {
        it: `Si è verificato un errore.`,
        en: `An error occurred.`,
        de: `Es ist ein Fehler aufgetreten.`
      }
    }
  }
</script>