<template>
  <Autenticatore logo={logo_completo_semplice} {rotte}>
    <div class="contenuto-barra-laterale">
      <Localizzatore apertura="sotto" esteso={true}/>
    </div>
  </Autenticatore>
</template>

<style>
  /* Barra laterale. */
  :global(div.contenuto-barra-laterale) {
    height:           100%;
    display:          flex;
    flex-direction:   column;
  }

  /* Sfondo principale. */
  :global(main#rotta > div.contenuto) {
    background: var(--colore-sfondo);
  }
  :global(div.contenitore-base) {
    padding:    15px;
    height:     100%;
    width:      100%;
  }

  /* Rotte. */
  :global(div.contenuto-barra-laterale > div.rotte) {
    position:         relative;
  }
  :global(div.contenuto-barra-laterale > div.rotte) {
    flex-grow:        1;
  }
</style>

<script>
  import Autenticatore from "@/base/rotte/Autenticatore.svelte"
  import Accesso from "@/documentazione/rotte/Accesso.svelte"
  import Documentazione from "@/documentazione/rotte/Documentazione.svelte"
  import Localizzatore from "@/base/componenti/Localizzatore.svelte"
  import logo_completo_semplice from "@/base/immagini/logo-completo-semplice.svg"

  const rotte = {
    "/": Documentazione,
    "/accesso": Accesso }
</script>