<template>
  <div class="titolo-principale {classi}">
    {#if !senza_barra_laterale}
      <div id="apritore-barra-laterale">
        <Bottone
            piccolo={true}
            variante="secondario-invertito">
          <Icona tipo="menù"/>
        </Bottone>
      </div>
    {/if}

    <h2>
      {#if titolo}
        {titolo}
      {/if}
    </h2>

    <slot/>

    <div class="azioni">
      <slot name="azioni"></slot>
    </div>
  </div>
</template>

<style>
  :global(div.titolo-principale) {
    display:            flex;
    justify-content:    flex-start;
    align-items:        center;
    margin:             0;
    padding:            calc(var(--padding-base-main) * 1.2) var(--padding-base-main);
    position:           relative;
    width:              100%;
  }
  :global(div.titolo-principale::after) {
    content:            "";
    background-color:   var(--colore-linea);
    position:           absolute;
    height:             1px;
    bottom:             0;
    left:               0;
    right:              0
  }
  :global(div.titolo-principale h2) {
    margin:             0;
    padding:            0;
    font-weight:        800;
    font-size:          24px;
    line-height:        36px;
  }
  :global(div.titolo-principale .azioni) {
    margin-left: auto;
    max-height: 36px;
    display: flex;
    align-items: center;
  }
  :global(div#apritore-barra-laterale) {
    width:              0;
    z-index:            10;
    transition:         width 0.5s ease;
    overflow:           hidden;
  }
  :global(div#barra-laterale.chiusa + main#rotta div#apritore-barra-laterale) {
    width:  55px;
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"

  export let classi = ""
  export let senza_barra_laterale = false
  export let titolo = null
</script>
