<template>
  {#if in_caricamento}
    <Caricatore/>
  {:else}
    {#if mostra_barra_laterale}
      <BarraLaterale
          {logo}
          chiusa={barra_laterale_chiusa}
          richiudibile={barra_laterale_richiudibile}>
        <slot/>
      </BarraLaterale>
    {/if}
    <Router routes={rotte}/>
  {/if}
</template>

<script>
  import Router from "svelte-spa-router"
  import { location } from 'svelte-spa-router'
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import BarraLaterale from "@/base/componenti/BarraLaterale.svelte"
  import { utente_corrente } from "@/base/sorgenti/svuby"
  import { onMount } from "svelte"

  export let rotte = {}
  export let logo = null
  export let in_attesa_esterna = false
  export let barra_laterale = true

  let gettone = biscottini.prendi("gettone")
  let barra_laterale_chiusa = false
  let in_autenticazione = true
  let utente_autenticato = true

  $: barra_laterale_richiudibile = !$location.includes("nuovo_abbonamento")
  $: in_caricamento = in_attesa_esterna || in_autenticazione
  $: mostra_barra_laterale = utente_autenticato && $utente_corrente != null && barra_laterale

  //#
  // Recupera utente da gettone autorizzazione depositato
  // nei biscottini.
  async function recupera_utente_da_gettone() {
    if (gettone == null) {
      if ($utente_corrente != null) utente_corrente.slogga(true)
      in_autenticazione = false
      return
    }

    let risposta = await retro.chiama("GET", retro.estremi.anagrafe.me)
    if (risposta.stato == 401) {
      utente_corrente.slogga()
      navigatore.verso(`/accesso?redirect_url=${window.location.href}`)
    }
    utente_autenticato = risposta.contenuto.utente

    if ($utente_corrente == null)
      utente_corrente.cambia_in(utente_autenticato)
    if ($utente_corrente._id != utente_autenticato._id)
      utente_corrente.cambia_in(utente_autenticato)

    console.log(`Utente corrente: ${$utente_corrente.email.colore("azzurro")}`)
    in_autenticazione = false
    utente_autenticato = true
  }

  // Quando pronto, recupera l'utente dal gettone.
  onMount(recupera_utente_da_gettone)
</script>
