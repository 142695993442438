<template>
  <Selezione
      piana={!esteso}
      apertura={apertura}
      valore={$localizzazione}
      opzioni={opzioni_localizzazione}
      on:change={cambia_localizzazione}
      let:opzione={opzione}
      let:opzione_selezionata={opzione_selezionata}>
    <div slot="valore">
      <div
          class="opzione-localizzatore"
          class:esteso={esteso}>
        <img src={ottieni_bandiera_nazione(opzione_selezionata)} alt={opzione_selezionata.testo}/>
        <span>{opzione_selezionata.testo}</span>
      </div>
    </div>

    <div slot="opzione">
      <div
          class="opzione-localizzatore"
          class:esteso={esteso}>
        <img src={ottieni_bandiera_nazione(opzione)} alt={opzione.testo}/>
        <span>{opzione.testo}</span>
      </div>
    </div>
  </Selezione>
</template>

<style>
  :global(div.opzione-localizzatore) {
    display:      flex;
    align-items:  center;
  }
  :global(div.opzione-localizzatore > img) {
    width:        30px;
    margin-right: 7px;
  }
  :global(div.opzione-localizzatore.esteso > img) {
    width:        30px;
    margin-right: 14px;
  }
</style>

<script>
  import Selezione from "@/base/componenti/Selezione.svelte"
  import { localizzazione } from "@/base/sorgenti/svuby"
  import { bandiera_nazione } from "@/base/sorgenti/svuby"

  export let apertura = "sopra"
  export let esteso = false

  let opzioni_localizzazione_ridotte = [
    { valore: "it", testo: "IT" },
    { valore: "en", testo: "EN" },
    // { valore: "de", testo: "DE" }
  ]
  let opzioni_localizzazione_estese = [
    { valore: "it", testo: "Italiano" },
    { valore: "en", testo: "English" },
    // { valore: "de", testo: "Deutsch" }
  ]
  let opzioni_localizzazione = esteso ? opzioni_localizzazione_estese : opzioni_localizzazione_ridotte

  //#
  // Ottiene la bandiera nazione.
  function ottieni_bandiera_nazione(opzione) {
    let codice_nazione = opzione.valore
    if (opzione.valore == "en") codice_nazione = "gb"
    return bandiera_nazione(codice_nazione.toUpperCase())
  };

  //#
  // Cambia la localizzazione.
  function cambia_localizzazione(evento) {
    localizzazione.cambia_in(evento.detail.valore)
  }
</script>
