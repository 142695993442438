<template>
  {#if !autenticato}
    <Rotta schermo_intero={true} richiede_autenticazione={false}>
      <div id="accesso" class="documentazione">
        <Sfondo/>

        <div class="loghi-contenitore documentazione">
          <div class="logo-accesso HB">
            <img src={window.risolviImg(logo_completo_semplice_bianco)} alt="HBenchmark Logo White" height="52">
          </div>
        </div>

        <Modulo accesso_staff={true}/>
      </div>
    </Rotta>
  {/if}
</template>

<style>
  :global(div.contenitore-accesso) {
    min-height: 100%;
    overflow:   auto;
  }
  :global(div#accesso.documentazione) {
    --top-margin: 22%;
    display:      flex;
    align-items:  stretch;
    min-height:   100%;
  }
  :global(div#accesso.documentazione div.loghi-contenitore.documentazione) {
    position:   absolute;
    height:     55px;
    top:        calc(22vh - 55px);
    width:      100vw;
    display:    flex;
    justify-content: space-between;
  }
  :global(div#accesso.documentazione div.loghi-contenitore.documentazione div.logo-accesso) {
    width: calc(50vw - (450px / 2));
    display: flex;
    justify-content: center;
  }
  :global(div#accesso.documentazione div.loghi-contenitore.documentazione div.logo-accesso > img) {
    height: 100%;
  }
  :global(div#accesso.documentazione section#modulo-accesso) {
    left: 50%;
    transform: translateX(-50%);
  }
</style>

<script>
  import Rotta from "@/base/componenti/Rotta.svelte"
  import Sfondo from "@/hstaff/componenti/accesso/Sfondo.svelte"
  import Modulo from "@/base/componenti/moduli/Accesso.svelte"
  import logo_completo_semplice_bianco from "@/base/immagini/logo-completo-semplice-bianco.svg"
  import { onMount } from "svelte"
  import { utente_corrente } from "@/base/sorgenti/svuby"

  let autenticato = $utente_corrente != null

  ////
  // Verifica che non ci sia alcun utente autenticato.
  function verifica_utente_non_autenticato() {
    if (!autenticato) return
    else navigatore.verso("/")
  }

  ////
  // Quando pronto, verifica che non ci sia alcun utente corrente.
  // Se invece esiste un utente autenticato, rimanda alla pagina principale.
  onMount(verifica_utente_non_autenticato)
</script>
