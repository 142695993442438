/*
  -- FINESTRA --
  Varie funzioni di grande utilità inserite nella variabile `window`.
*/

// Debounce.
window.debounce = function(func, wait, immediate) {
	let timeout

	return function() {
		let context = this, args = arguments
		let later = function() {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		let callNow = immediate && !timeout

		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

/**
 * Funzione che risolve l'url delle immagini cross-platform.
 * E' stata creata in quanto c'erano divergenze nel modo in cui rollup
 * risolveva le il path su Windows e su Mac.
 *
 * @param {string} url
 */
window.risolviImg = function (url) {
	if(!url){
		throw new Error("Manca il path dell'immagine da risolvere.")
	}

	let toReturn = url.trim();

	if (!toReturn.startsWith("/")) {
		toReturn = "/" + toReturn
	}

	if(!toReturn.includes("immagini")){
		toReturn = toReturn.replace(/^\//, "/immagini/")
	}

	return toReturn
}

////
// Ricava i parametri statistica rimuovendo i campi non utilizzati dalla statistica.
window.ricava_parametri_statistica = function(statistica) {
	let parametri_statistica = { ...statistica }
	delete parametri_statistica.cornice_temporale.periodi
	delete parametri_statistica.parametri_originali
	delete parametri_statistica.serie
	return parametri_statistica
}
