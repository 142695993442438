<template>
  <input
      type="number"
      name={nome}
      placeholder={segnaposto}
      disabled={disabilitato}
      aria-label={attributi.etichetta}
      min={attributi.minimo}
      max={attributi.massimo}
      autocomplete={autocompletamento}
      maxlength={lunghezza_massima}
      step={passo}
      bind:value={valore}
      on:keydown
      on:input
      on:focus
      on:blur/>
</template>

<script>
  export let nome = ""
  export let valore = ""
  export let segnaposto = ""
  export let disabilitato = false
  export let attributi = {}
  export let lunghezza_massima = null
  export let passo = 1

  let autocompletamento = attributi.autocompletamento == false ? "off" : null

  // Per compatibilità con altri inserimenti.
  attributi
</script>
