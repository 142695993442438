/*
  -- RETE --
  Varie funzioni di grande utilità che facilitano la gestione
  delle chiamate di rete.
*/

// Dominio.
window.dominio = window.location.host

// Dominio di primo livello.
Object.defineProperty(window, "dominio_primo_livello", {
  get: function() {
    let parti_dominio_senza_porta = window.dominio.split(":")[0].split(".")
    return parti_dominio_senza_porta[0]
  },
  enumerable: false
})

// Dominio di ultimo livello.
Object.defineProperty(window, "dominio_ultimo_livello", {
  get: function() {
    let parti_dominio_senza_porta = window.dominio.split(":")[0].split(".")
    return parti_dominio_senza_porta.slice(parti_dominio_senza_porta.length - 2).join(".")
  },
  enumerable: false
})

// Serializza un oggetto in parametri URL.
window.serializza = function(params, prefix) {
  const query = Object.keys(params).map((key) => {
    const value  = params[key];

    if (params.constructor === Array)
      key = `${prefix}[]`;
    else if (params.constructor === Object)
      key = (prefix ? `${prefix}[${key}]` : key);

    if (value == null)
      return `${key}=`
    else if (typeof value === "object")
      return serializza(value, key);
    else
      return `${key}=${encodeURIComponent(value)}`;
  });

  return [].concat.apply([], query).join("&");
}

// Legge il contenuto della risposta.
Response.prototype.content = async function() {
  let body = await this.text()

  try {
    return JSON.parse(body)
  } catch {
    return body
  }
}

// Attributi risposta.
Object.defineProperty(Response.prototype, "stato", {
  get: function() {
    return this.status
  },
  enumerable: false
})
Object.defineProperty(Response.prototype, "riuscita", {
  get: function() {
    return this.status < 400
  },
  enumerable: false
})
Object.defineProperty(Response.prototype, "fallita", {
  get: function() {
    return this.status >= 400
  },
  enumerable: false
})

////
// Retro (backend)
window.retro = {}

switch (window.location.hostname) {
  case "portal.hbenchmark.it":
  case "camping.hbenchmark.it":
  case "hstaff.hbenchmark.it":
  case "bestwestern.hbenchmark.it":
  case "unahotels.hbenchmark.it":
  case "dev.hbenchmark.it":
    retro.indirizzo = `https://ir.hbenchmark.it`
    break;
  case "portal.lab.hbenchmark.it":
  case "hstaff.lab.hbenchmark.it":
    retro.indirizzo = `https://ir.lab.hbenchmark.it`
    break;
  default:
    // retro.indirizzo = `https://ir.hbenchmark.it`
    retro.indirizzo = `http://ir.hbenchmark.loc`
    break;
}

// Estremi del Retro.
retro.estremi = {
  anagrafe: {
    me:                             `${retro.indirizzo}/anagrafe/me`,
    esiste:                         `${retro.indirizzo}/anagrafe/esiste`,
    registra:                       `${retro.indirizzo}/anagrafe/registra`,
    autentica:                      `${retro.indirizzo}/anagrafe/autentica`,
    autorizza_sso:                  `${retro.indirizzo}/anagrafe/sso/autorizza`,
    password:                       `${retro.indirizzo}/anagrafe/password`
  },
  portale: {
    bollette:                       `${retro.indirizzo}/portale/bollette`,
    bolletta(id)                    { return `${retro.indirizzo}/portale/bollette/${id}` },
    codifiche:                      `${retro.indirizzo}/portale/codifiche`,
    concorrenti:                    `${retro.indirizzo}/portale/concorrenti`,
    concorrente(id)                 { return `${retro.indirizzo}/portale/concorrenti/${id}` },
    cruscotti:                      `${retro.indirizzo}/portale/cruscotti`,
    cruscotto(id)                   { return `${retro.indirizzo}/portale/cruscotti/${id}` },
    disponibilità:                  `${retro.indirizzo}/portale/disponibilita`,
    eventi:                         `${retro.indirizzo}/portale/eventi`,
    evento(id)                      { return `${retro.indirizzo}/portale/eventi/${id}` },
    gruppi:                         `${retro.indirizzo}/portale/gruppi`,
    indicatori:                     `${retro.indirizzo}/portale/indicatori`,
    rapporti:                       `${retro.indirizzo}/portale/rapporti`,
    rapporto(id)                    { return `${retro.indirizzo}/portale/rapporti/${id}` },
    resoconti_finanziari:           `${retro.indirizzo}/portale/resoconti_finanziari`,
    resoconto_finanziario(id)       { return `${retro.indirizzo}/portale/resoconti_finanziari/${id}` },
    indice_resoconti_finanziari(id) { return `${retro.indirizzo}/portale/resoconti_finanziari/indice/${id}` },
    soggiorni:                      `${retro.indirizzo}/portale/soggiorni`,
    struttura(id)                   { return `${retro.indirizzo}/portale/strutture/${id}` },
    statistica_nuova:               `${retro.indirizzo}/portale/statistica`,
    statistiche:                    `${retro.indirizzo}/portale/statistiche`,
    statistica(id)                  { return `${retro.indirizzo}/portale/statistiche/${id}` },
    trasmissioni:                   `${retro.indirizzo}/portale/trasmissioni`
  },
  licenze: {
    abbonamenti:                    `${retro.indirizzo}/licenze/abbonamenti`,
    abbonamento(id)                 { return `${retro.indirizzo}/licenze/abbonamenti/${id}` },
    buoni_sconto:                   `${retro.indirizzo}/licenze/buoni_sconto`,
    clienti:                        `${retro.indirizzo}/licenze/clienti`,
    piani:                          `${retro.indirizzo}/licenze/piani`,
    gestionali:                     `${retro.indirizzo}/licenze/gestionali`,
    pagamenti:                      `${retro.indirizzo}/licenze/pagamenti`,
    pagamento(id)                   { return `${retro.indirizzo}/licenze/pagamento/${id}` },
    strutture:                      `${retro.indirizzo}/licenze/strutture`,
    struttura(id)                   { return `${retro.indirizzo}/licenze/strutture/${id}` }
  },
  hstaff: {
    azioni:                         `${retro.indirizzo}/hstaff/azioni`,
    eventi:                         `${retro.indirizzo}/hstaff/eventi`,
    evento(id)                      { return `${retro.indirizzo}/hstaff/eventi/${id}` },
    abbonamenti:                    `${retro.indirizzo}/hstaff/abbonamenti`,
    abbonamento(id)                 { return `${retro.indirizzo}/hstaff/abbonamenti/${id}` },
    attività:                       `${retro.indirizzo}/hstaff/attivita`,
    attività_singola(id)            { return `${retro.indirizzo}/hstaff/attivita/${id}` },
    bollette:                       `${retro.indirizzo}/hstaff/bollette`,
    bolletta(id)                    { return `${retro.indirizzo}/hstaff/bollette/${id}` },
    buoni_sconto:                   `${retro.indirizzo}/hstaff/buoni_sconto`,
    catene:                         `${retro.indirizzo}/hstaff/catene`,
    catena(id)                      { return `${retro.indirizzo}/hstaff/catene/${id}` },
    chiusure:                       `${retro.indirizzo}/hstaff/chiusure`,
    clienti:                        `${retro.indirizzo}/hstaff/clienti`,
    cliente(id)                     { return `${retro.indirizzo}/hstaff/clienti/${id}` },
    codifiche:                      `${retro.indirizzo}/hstaff/codifiche`,
    concorrenti:                    `${retro.indirizzo}/hstaff/concorrenti`,
    concorrente(id)                 { return `${retro.indirizzo}/hstaff/concorrenti/${id}` },
    disponibilità:                  `${retro.indirizzo}/hstaff/disponibilita`,
    gestionali:                     `${retro.indirizzo}/hstaff/gestionali`,
    gestionale(id)                  { return `${retro.indirizzo}/hstaff/gestionali/${id}` },
    gettone:                        `${retro.indirizzo}/hstaff/gettone`,
    gruppi_personalizzati:          `${retro.indirizzo}/hstaff/gruppi_personalizzati`,
    gruppo_personalizzato(id)       { return `${retro.indirizzo}/hstaff/gruppi_personalizzati/${id}` },
    località:                       `${retro.indirizzo}/hstaff/localita`,
    località_singola(id)            { return `${retro.indirizzo}/hstaff/localita/${id}` },
    rifiuti:                        `${retro.indirizzo}/hstaff/rifiuti`,
    soggiorni:                      `${retro.indirizzo}/hstaff/soggiorni`,
    statistica:                     `${retro.indirizzo}/hstaff/statistica`,
    strutture:                      `${retro.indirizzo}/hstaff/strutture`,
    struttura(id)                   { return `${retro.indirizzo}/hstaff/strutture/${id}` },
    territori:                      `${retro.indirizzo}/hstaff/territori`,
    territorio(id)                  { return `${retro.indirizzo}/hstaff/territori/${id}` },
    totale_trasmissioni:            `${retro.indirizzo}/hstaff/totale_trasmissioni`,
    trasmissioni:                   `${retro.indirizzo}/hstaff/trasmissioni`,
    trasmissione(id)                { return `${retro.indirizzo}/hstaff/trasmissioni/${id}` },
    utenti:                         `${retro.indirizzo}/hstaff/utenti`,
    utente(id)                      { return `${retro.indirizzo}/hstaff/utenti/${id}` },
    zone:                           `${retro.indirizzo}/hstaff/zone`,
    zona(id)                        { return `${retro.indirizzo}/hstaff/zone/${id}` },
    ricerca: {
      catene:                       `${retro.indirizzo}/hstaff/ricerca/catene`,
      clienti:                      `${retro.indirizzo}/hstaff/ricerca/clienti`,
      commesse:                     `${retro.indirizzo}/hstaff/ricerca/commesse`,
      eventi:                       `${retro.indirizzo}/hstaff/ricerca/eventi`,
      gestionali:                   `${retro.indirizzo}/hstaff/ricerca/gestionali`,
      località:                     `${retro.indirizzo}/hstaff/ricerca/localita`,
      rivenditori:                  `${retro.indirizzo}/hstaff/ricerca/rivenditori`,
      strutture:                    `${retro.indirizzo}/hstaff/ricerca/strutture`,
      territori:                    `${retro.indirizzo}/hstaff/ricerca/territori`,
      utenti:                       `${retro.indirizzo}/hstaff/ricerca/utenti`,
      zone:                         `${retro.indirizzo}/hstaff/ricerca/zone`,
    }
  },
  best_western: {
    codifiche:                      `${retro.indirizzo}/best_western/codifiche`,
    strutture:                      `${retro.indirizzo}/best_western/strutture`,
    soggiorni:                      `${retro.indirizzo}/best_western/soggiorni`,
    lista_soggiorni(id)             { return `${retro.indirizzo}/best_western/soggiorni/${id}` }
  },
  una_hotels: {
    codifiche:                      `${retro.indirizzo}/una_hotels/codifiche`,
    strutture:                      `${retro.indirizzo}/una_hotels/strutture`
  }
}

// Chiamata di rete al Retro.
retro.chiama = async function(metodo, estremo, parametri = null, gestore = null, cappio = null) {
  let intestazioni = { "Content-Type": "application/json" }
  let gettone = biscottini.prendi("gettone")
  if (gettone != null)
    intestazioni["Authorization"] = `Bearer ${gettone}`

  let parametri_finali = { method: metodo, headers: intestazioni }

  if (cappio != null) parametri_finali.signal = cappio

  if (parametri != null) {
    if (metodo != "GET" && metodo != "HEAD" )
      parametri_finali.body = JSON.stringify(parametri)
    else
      estremo = `${estremo}?${serializza(parametri)}`
  }

  if (gestore != null) {
    return fetch(estremo, parametri_finali)
      .then(response => {
        gestore.successo({ ...response, contenuto: response.content })
      })
      .catch(error => {
        gestore.fallimento(error)
      })
  } else {
    let risposta
    await fetch(estremo, parametri_finali)
      .then(async (response) => {
        let content = await response.content()
        
        if (response.status == 401) throw { non_autorizzato: true }

        response.contenuto = content
        risposta = response
      })
      .catch(error => {
        if (error instanceof DOMException && error.name == "AbortError") return
        else throw error
      })

    return risposta
  }
}